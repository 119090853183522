@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
.tiktok-embed {
  max-width: 100% !important;
  max-height: 100% !important;
  overflow: hidden !important;
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-rgb: 128, 128, 128;
}

html {
  overflow-x: hidden;
  overscroll-behavior-y: smooth;
  background-color: #0B0B0C;
}

body {
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch;
  min-height: 100vh;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 40vh;
  margin: 0 auto;
}

.video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 177.78%;
}

.video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.rounded-video {
  border-radius: 10px;
}

.navbar {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background:#ffffff33;
  color: #ffffff;

}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo img {
  height: 40px;
  margin-left: 1rem; 
  margin-right: 5rem; 
}

.navbar-menu {
  display: flex;
  list-style: none;

}

.navbar-menu li {
  margin-right: 2rem;
}

.navbar-menu li a {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.2rem;
}

.navbar-account {
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-left: auto; 
  margin-right: 1rem; 
}
.rounded-logo {
  border-radius: 50%;
}

@keyframes scrollUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-50%);
  }
}

@keyframes scrollDown {
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0);
  }
} 